import { useEffect, useState } from 'react';
import { useFloating, flip, shift, autoUpdate, offset } from '@floating-ui/react-dom';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Link } from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { useMobile } from '@/shared/hooks';
import SettingsSliders from '@/shared/icons/flip-ui-kit/small/SettingsSliders';
import { KnifeIcon } from '@/shared/icons/small';
import { decimalRegex } from '@/shared/utils/regex';
import useSettingsStore from '../../../hooks/useSettingsStore';
import useSudo from '../../../hooks/useSudo';
import ShieldIcon from '../SwapCard/icons/Shield';

const SLIPPAGE_MIN = 0.05;
const SLIPPAGE_MAX = 50;
const SUDO_SLIPPAGE_MIN = -SLIPPAGE_MAX;

export const SlippageProtectionSection = ({ open }: { open?: boolean }) => {
  const {
    setSlippageTolerancePercent,
    setSwapDeadlineMinutes,
    slippageTolerancePercent,
    swapDeadlineMinutes,
  } = useSettingsStore();
  const isSudo = useSudo();

  const [slippageToleranceString, setSlippageToleranceString] = useState('');
  const [swapDeadlineString, setSwapDeadlineString] = useState('');
  const onSlippageToleranceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSudo || decimalRegex(2).test(e.target.value)) {
      setSlippageToleranceString(e.target.value);
    }
  };
  const onSlippageToleranceInputBlur = () => {
    const min = isSudo ? SUDO_SLIPPAGE_MIN : SLIPPAGE_MIN;
    let validated = Number(slippageToleranceString);
    if (!validated || validated < min) validated = min;
    if (validated > SLIPPAGE_MAX) validated = SLIPPAGE_MAX;

    setSlippageToleranceString(String(validated));
    setSlippageTolerancePercent(validated);
  };

  const onSwapDeadlineInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (decimalRegex(0).test(e.target.value)) {
      setSwapDeadlineString(e.target.value);
    }
  };
  const onSwapDeadlineInputBlur = () => {
    let validated = Number(swapDeadlineString);
    if (!validated || validated < 1) validated = 1;
    if (validated > 45) validated = 45;

    setSwapDeadlineString(String(validated));
    setSwapDeadlineMinutes(validated);
  };

  useEffect(() => {
    if (open) {
      setSlippageToleranceString(String(slippageTolerancePercent));
      setSwapDeadlineString(String(swapDeadlineMinutes));
    }
  }, [open]);

  return (
    <>
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
          <div className="flex items-center gap-x-1">
            <ShieldIcon className="text-cf-blue-2" />
            Slippage Protection
          </div>
        </div>
        <span className="text-12 text-cf-light-2">
          Your deposit will be refunded if the price changes unfavourable above this percentage.{' '}
          <Link
            href="https://docs.chainflip.io/swapping/swapping-basics#minimum-accepted-price-slippage-protection"
            target="_blank"
            className="underline"
          >
            Learn more
          </Link>
        </span>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row items-center justify-between font-aeonikMedium">
          <div className="flex flex-row gap-1 text-12 text-cf-light-2">
            <span>Slippage tolerance</span>
            <QuestionMarkTooltip content="Maximum accepted difference between quote price and swap price" />
          </div>
          <div className="flex flex-row items-center gap-1">
            <div
              className={classNames(
                'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 text-cf-light-3',
                'transition  focus-within:text-cf-white  hover:text-cf-white',
              )}
            >
              <input
                data-testid="slippage-tolerance-input"
                inputMode="decimal"
                className="w-0 grow bg-transparent py-1 text-right outline-none"
                value={slippageToleranceString}
                onChange={onSlippageToleranceInputChange}
                onBlur={onSlippageToleranceInputBlur}
                autoFocus
              />
              <span className="text-cf-light-2">%</span>
            </div>
            {isSudo && (
              <button
                data-testid="sudo-set-max-slippage"
                type="button"
                className="text-cf-red-2 hover:text-cf-red-1"
                onClick={() => {
                  setSlippageToleranceString(SUDO_SLIPPAGE_MIN.toString());
                  setSlippageTolerancePercent(SUDO_SLIPPAGE_MIN);
                }}
              >
                <KnifeIcon />
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between font-aeonikMedium">
          <div className="flex flex-row gap-1 text-12 text-cf-light-2">
            <span>Swap deadline</span>
            <QuestionMarkTooltip content="Time until deposit is refunded if price exceeds slippage tolerance" />
          </div>
          <div
            className={classNames(
              'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 text-cf-light-3',
              'transition focus-within:text-cf-white hover:text-cf-white',
            )}
          >
            <input
              data-testid="swap-deadline-input"
              inputMode="numeric"
              className="w-0 grow bg-transparent py-1 text-right outline-none"
              value={swapDeadlineString}
              onChange={onSwapDeadlineInputChange}
              onBlur={onSwapDeadlineInputBlur}
            />
            <span className="text-cf-light-2">min</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const SlippageTolerancePopup = () => (
  <Popover>
    {({ open }) => {
      const isMobile = useMobile(1386);

      const { slippageTolerancePercent } = useSettingsStore();

      const { floatingStyles, refs } = useFloating({
        open,
        placement: isMobile ? 'top-start' : 'bottom-start',
        middleware: [offset(8), flip(), shift({ padding: 16 })],
        whileElementsMounted: autoUpdate,
      });

      return (
        <>
          <Popover.Button
            ref={refs.setReference}
            className={classNames(
              'flex items-center gap-x-0.5 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 p-2 font-aeonikMedium text-12 text-cf-light-3',
              'transition duration-100 ease-out hover:bg-cf-gray-5 hover:text-cf-light-4',
              open && 'border-cf-light-1 bg-cf-gray-5 text-cf-light-4',
            )}
            data-testid="slippage-popup-toggle"
          >
            <SettingsSliders />
            {slippageTolerancePercent}%
          </Popover.Button>
          <Transition
            className="relative z-10 transition-[opacity,scale]"
            enter="ease-out duration-100 scale-100"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <Popover.Panel className="flex w-[300px] flex-col space-y-3 rounded-md border border-cf-gray-5 bg-cf-gray-4 p-3">
              <SlippageProtectionSection open={open} />
            </Popover.Panel>
          </Transition>
        </>
      );
    }}
  </Popover>
);
