import React, { Fragment, useState } from 'react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import GreenSpinnerJSON from '@/shared/animations/spinner-green.json';
import { type ChainflipToken } from '@/shared/assets/tokens';
import { ChainTokenLogo, Link, Tooltip } from '@/shared/components';
import Pill from '@/shared/components/flip-ui-kit/Pill';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { Rate } from '@/shared/components/Rate';
import RouteTooltip from '@/shared/components/RouteTooltip';
import { useMobile, usePriceOracle } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import ArrowRightIcon from '@/shared/icons/ArrowRight';
import Chevron from '@/shared/icons/flip-ui-kit/small/Chevron';
import { ChevronIcon, EmojiSadIcon } from '@/shared/icons/large';
import {
  ChunksIcon,
  DollarIcon,
  GasIcon,
  GlobeIcon,
  RouteIcon,
  ShieldTickIcon,
  SliceIcon,
  TimeSpeedIcon,
} from '@/shared/icons/small';
import { formatUsdValue } from '@/shared/utils';
import Lottie from '@/shared/utils/Lottie';
import { DurationBadge } from './DurationBadge';
import usePriceDelta from '../../../hooks/usePriceDelta';
import useSettingsStore from '../../../hooks/useSettingsStore';
import { integrationManager, type RouteResponse } from '../../../integrations';
import { calculateMinPrice, calculateMinReceived } from '../../../integrations/chainflip';
import { PlatformFees } from '../../StatusPageV2/SwapDetailsV2';
import { UsdAmount } from '../../UsdAmount';
import MarketData from '../MarketData';

const RouteSection = ({ route, disabled }: { route: RouteResponse; disabled: boolean }) => {
  const [showRouteDetails, setShowRouteDetails] = useState<boolean>(false);

  if (route.integration === 'chainflip') {
    const sourceAsset = route.srcToken.chainflipId;
    const destinationAsset = route.destToken.chainflipId;
    return (
      <Tooltip
        content={
          <RouteTooltip
            type="Route"
            swapRequest={{
              sourceAsset,
              destinationAsset,
              executedSwaps: {
                totalCount: 1,
                aggregates: {
                  sum: {
                    swapInputAmount: route.srcAmount.toString(),
                    swapInputValueUsd: '0',
                    intermediateAmount: route.steps[1]?.srcAmount.toString() ?? '0',
                    intermediateValueUsd: '0',
                    swapOutputAmount: route.destAmount.toString(),
                    swapOutputValueUsd: '0',
                  },
                },
              },
            }}
          />
        }
      >
        <div className="flex items-center gap-x-1">
          {route.steps.map((step, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${route.id}-${index}`} className="flex items-center gap-x-1">
              <ChainTokenLogo
                token={{
                  symbol: step.srcToken.symbol,
                  chain: step.srcToken.chain,
                }}
                size="small"
                displayChainLogo={false}
              />
              <ArrowRightIcon width="16px" height="16px" className="text-cf-light-1" />
              {index === route.steps.length - 1 && (
                <ChainTokenLogo
                  token={{
                    symbol: step.destToken.symbol,
                    chain: step.destToken.chain,
                  }}
                  size="small"
                  displayChainLogo={false}
                />
              )}
            </span>
          ))}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div className="flex items-center justify-around">
        <div className="flex items-center justify-around space-x-1">
          {route.steps.map((step, index) => (
            <Fragment key={step.protocolName + step.srcToken.symbol}>
              <ChainTokenLogo token={step.srcToken} size="small" displayChainLogo={index !== 1} />
              <ArrowRightIcon className="text-cf-light-1" width="16px" height="16px" />
              {index === route.steps.length - 1 && (
                <ChainTokenLogo token={step.destToken} size="small" />
              )}
            </Fragment>
          ))}
        </div>

        <button
          type="button"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            setShowRouteDetails(!showRouteDetails);
          }}
        >
          <Chevron
            flip={!showRouteDetails}
            className="cursor-pointer text-cf-light-3 duration-100"
          />
        </button>
      </div>

      <motion.div
        className="!mt-0 h-0 overflow-hidden font-aeonikMedium text-13 text-cf-light-2"
        animate={showRouteDetails ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: '0px', opacity: 0 },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="mt-2 flex flex-col space-y-2">
          {route.steps.map((step) => (
            <div key={step.protocolName + step.srcToken.symbol}>
              <div className="ml-1 flex items-end space-x-1">
                <div className="text-cf-light-2">
                  Swap{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.srcAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.srcToken.symbol} to{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.destAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.destToken.symbol} via {step.protocolName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export const RouteCardLoading = () => (
  <div className="flex h-[334px] items-center justify-center space-y-3 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-5 text-14 text-cf-light-1 backdrop-blur-[6px] duration-150">
    <div className="flex items-center">
      <Lottie as="div" className="mr-2 h-5 w-5" animationData={GreenSpinnerJSON} autoplay loop />
      <span>Fetching routes...</span>
    </div>
  </div>
);
export const RouteCardNotFound = () => (
  <div className="flex h-[334px] cursor-pointer items-center justify-center space-y-3 p-5 duration-150">
    <div className="flex flex-col items-center justify-center space-y-1">
      <EmojiSadIcon className="text-cf-light-2" width={60} height={60} />
      <span className="px-20 text-center text-14 text-cf-light-2">
        No routes available at this time. Please try a different pair.
      </span>
    </div>
  </div>
);

type RouteCardProps = {
  route: RouteResponse;
  onRouteSelected: (route: RouteResponse) => void;
  isSelected?: boolean;
  differenceFromWorstRoute?: string | undefined;
  disabled?: boolean;
  secondsUntilNextRefresh: string;
};

export const RouteCard = ({
  route,
  onRouteSelected,
  isSelected = false,
  differenceFromWorstRoute,
  disabled = false,
  secondsUntilNextRefresh,
}: RouteCardProps) => {
  const isMobile = useMobile();
  const priceOracle = usePriceOracle();
  const integrationName = integrationManager.getName(route.integration);
  const Logo = integrationManager.getLogo(route.integration);
  const { price: destTokenPrice } = useTokenPrice(route.destToken);
  const { delta, color } = usePriceDelta(route);
  const { fillOrKillEnabled, slippageTolerancePercent: storeSlippageTolerancePercent } =
    useSettingsStore();
  const { toggle: toggleDetails, value: displayDetails } = useBoolean(isSelected);

  let slippageTolerancePercent;
  let minReceived;
  if (fillOrKillEnabled && route.integration === 'chainflip') {
    slippageTolerancePercent = storeSlippageTolerancePercent;
    minReceived = calculateMinReceived(
      route,
      calculateMinPrice(route.integrationData, slippageTolerancePercent),
    );
  }

  const isDca = route.integration === 'chainflip' && route.integrationData.dcaParams;
  const chunks =
    route.integration === 'chainflip' && route.integrationData.dcaParams?.numberOfChunks;

  const totalPlatformFeeUsd = BigNumber.sum(
    ...route.platformFees.map((fee) =>
      fee.amount.mul(priceOracle.getPrice(fee.token) ?? 0).toBigNumber(),
    ),
    0,
  );

  return (
    <div
      role="button"
      tabIndex={0}
      data-testid={isDca ? 'dca-route-card' : 'regular-route-card'}
      className={classNames('w-full space-y-3 rounded-md border p-4 pb-0 duration-150', {
        'border-cf-gray-4 bg-cf-gray-3': disabled,
        'border-cf-gray-5 bg-[#2C2C2C] shadow-[0px_0px_0px_3px_rgba(255,255,255,0.04),0px_0px_14px_0px_rgba(0,0,0,0.32),3px_3px_18px_0px_rgba(0,0,0,0.16)_inset]':
          isSelected,
        'border-cf-gray-4 bg-cf-gray-3-5 shadow-grayPop2 backdrop-blur-[6px] hover:border-cf-gray-5 hover:bg-[#2C2C2C]':
          !isSelected,
      })}
      onClick={() => {
        if (isSelected) {
          toggleDetails();
        }
        onRouteSelected(route);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between space-x-1">
          {isDca ? (
            <Pill
              text="Best Value"
              color={isSelected ? 'white' : 'neutral'}
              Icon={DollarIcon}
              iconColor="text-cf-green-3"
            />
          ) : (
            <Pill
              text="Faster"
              color={isSelected ? 'white' : 'neutral'}
              Icon={TimeSpeedIcon}
              iconColor="text-cf-blue-4"
            />
          )}
          {isDca && (
            <Pill
              text={`${chunks} chunks`}
              color={isSelected ? 'white' : 'neutral'}
              Icon={ChunksIcon}
            />
          )}
        </div>
        <div className="flex items-center gap-x-1">
          {!isMobile && (
            <MarketData
              disabled={disabled}
              route={route}
              spinner={
                <span className="text-12 text-cf-light-2">({secondsUntilNextRefresh}s)</span>
              }
              className="rounded-md border border-cf-gray-5 bg-cf-gray-4 text-cf-light-3 enabled:hover:border-cf-light-1 enabled:hover:bg-cf-gray-5 enabled:hover:text-cf-white"
            />
          )}
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              toggleDetails();
            }}
          >
            <ChevronIcon
              flip={!displayDetails}
              className={classNames(
                'rounded-md border border-cf-gray-5 bg-cf-gray-4 text-cf-light-3 hover:border-cf-light-1 hover:bg-cf-gray-5 hover:text-cf-white',
                displayDetails && 'border-cf-light-1 bg-cf-gray-5',
              )}
            />
          </button>
        </div>
      </div>

      <div className="flex items-center justify-start space-x-3">
        <div>
          <div className="flex items-center justify-start space-x-1">
            <span className="text-20 text-white">
              {route.destAmount.toPreciseFixedDisplay()} {route.destToken.symbol}
            </span>
            {differenceFromWorstRoute && Number(differenceFromWorstRoute) > 0 && (
              <span className="text-13 text-cf-green-3">
                (+{differenceFromWorstRoute} {route.destToken.symbol})
              </span>
            )}
          </div>
          <div className="flex items-center justify-start gap-x-1 text-13 text-cf-light-2">
            {destTokenPrice && (
              <Tooltip
                pointer={false}
                tooltipClassName="lg:min-w-[480px]"
                content="The difference between the global index price and the estimated rate"
              >
                <span>
                  <UsdAmount token={route.destToken} tokenAmount={route.destAmount} />
                  {delta && <span className={color}> ({delta.toFixed(1)}%)</span>}
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-13">
          <Rate
            depositAmount={route.srcAmount.toString()}
            sourceAsset={(route.srcToken as ChainflipToken).chainflipId}
            egressAmount={route.destAmount.toString()}
            destinationAsset={(route.destToken as ChainflipToken).chainflipId}
          />
        </div>
        <div className="flex items-center gap-x-2">
          <div className="flex items-center gap-x-1 font-aeonikMedium text-13 text-cf-light-3">
            <GasIcon />
            {formatUsdValue(totalPlatformFeeUsd)}
          </div>
          <span>{route.durationSeconds > 0 && <DurationBadge route={route} />}</span>
        </div>
      </div>
      <motion.div
        className="!mt-4 h-0 overflow-hidden font-aeonikMedium text-13 text-cf-light-2"
        animate={displayDetails ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: '0px', opacity: 0 },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="flex flex-col gap-y-3 border-t border-cf-gray-5 pt-3">
          <PlatformFees fees={route.platformFees} />
        </div>
        <div className="mt-3 flex flex-col gap-y-3 border-t border-cf-gray-5 py-3 pb-5">
          {minReceived && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <ShieldTickIcon /> Min. received
                <QuestionMarkTooltip
                  content={
                    <div>
                      Minimum amount received at the destination address based on slippage tolerance
                      and estimated fees.{' '}
                      <Link
                        href="https://docs.chainflip.io/swapping/swapping-basics#minimum-accepted-price-slippage-protection"
                        target="_blank"
                        color="green"
                      >
                        Learn more
                      </Link>
                    </div>
                  }
                />
              </div>
              <div className="text-cf-white">
                {minReceived?.toPreciseFixedDisplay()} {route.destToken.symbol}
              </div>
            </div>
          )}
          {route.integration === 'chainflip' && route.integrationData.dcaParams && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <SliceIcon /> Chunks
                <QuestionMarkTooltip
                  content={
                    <div>
                      Chunks are portions of your deposit amount that are split up and swapped over
                      time.{' '}
                      <Link
                        href="https://docs.chainflip.io/swapping/swapping-basics#dollar-cost-average-dca-improving-price"
                        target="_blank"
                        color="green"
                      >
                        Learn more
                      </Link>
                    </div>
                  }
                />
              </div>
              <div>{route.integrationData.dcaParams.numberOfChunks}</div>
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <RouteIcon /> Route
            </div>
            <div>
              <RouteSection route={route} disabled={disabled} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <GlobeIcon /> Protocol
            </div>
            <div>
              <div className="flex items-center justify-start space-x-1">
                <Logo width={13} height={13} />
                <span className="font-aeonikMedium">{integrationName}</span>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default RouteCard;
